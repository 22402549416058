
@keyframes shadow-beat {
  0%{
    text-shadow: 0px 3px 15px rgba(255, 255, 255, 0);
  }
  50%{
    text-shadow: 0px 3px 15px rgb(176, 66, 209);
  }
  100%{
    text-shadow: 0px 3px 15px rgba(255, 255, 255, 0);
  }
}

.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #3e497a;
  }
  
  .about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    background: transparent;
    color: #f0f0f0;
  }
  
  .about h2 {
    animation: shadow-beat 2s infinite;
    font-family: "Gothic edition", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 80px;
    text-align: center !important;
    color: #e9d35b;
    height: 50px;
    
  }
  
  .skils .programmer {
    height: '300px';
    width:'400px';
    margin: 'auto';
    display: 'flex';
    justify-content: 'center';
  }

  .about .prompt {
    margin: 1px 1px 1px 1px;
    width: 80%;
    font-size: 30px;
  }

  .about-header {
    color: white;
  }
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
  }
  
  .skills h1 {
    color: white;
  }
  
  .skills .list {

    list-style: none;
    width: 60%;
    text-align: left;
    color: white;
  }
  
  .list span {
    text-shadow: 0px 3px 15px rgb(0, 0, 0);
    font-size: 20px;
  }

  .coffeePlz {
    color: white;
    text-emphasis: bold;
  }

  .coffee {
    background: url('../assets/buymeacoffee.jpg');
    display: flex;
    justify-content: center;
    margin: auto;
    height: 150px;
    width: 540px;
    border-radius: 20px;
    transition: 0.3s;
  }

  
  .coffee:hover {
    box-shadow: 0px 3px 15px rgba(176, 66, 209, 0.747);
  }

  @media only screen and (max-width: 600px) {
    .about h2 {
      font-size: 40px;
    }
  
    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
    
    .coffee {
      background: url('../assets/buymeacoffeeLogo.jpg');
      display: flex;
      justify-content: center;
      margin: auto;
      height: 120px;
      width: 120px;
      border-radius: 20px;

    }
  }