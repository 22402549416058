

.resume-section {
    position: relative !important;
    padding-top: 110px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
  }
  
  .resume {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    margin: auto;

  }
  
  .resume-left {
    padding-right: 80px !important;
  }
  
  .resume-right {
    padding-left: 80px !important;
  }
  
  @media (max-width: 767px) {
    .resume-left {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  
    .resume-right {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }
  .resume .resume-title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .resume .resume-item {
    padding: 0 0 10px 25px;
    margin-top: -2px;
    border-left: 2px solid #a4b413;
    position: relative;
  }
  
  .resume .resume-item .resume-title {
    line-height: 18px;
    font-size: 0.9em;
    background: #5234795d;
    padding: 8px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .resume .resume-item ul {
    padding-left: 20px;
    text-align: justify;
  }
  
  .resume .resume-item ul li {
    padding-bottom: 10px;
    list-style: none;
  }
  
  .resume .resume-item:last-child {
    padding-bottom: 0;
  }
  
  .resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #8a49a8;
  }